import axios from "axios"

import config from "../service/config"

let headers = {
    'Accept': 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
}

let myAxios = axios.create({
    baseURL: config.API_URL,
    headers: headers,
})

let AuthAxios = axios.create({
    baseURL: config.API_URL,
    headers: headers,
    auth: {
        username: config.GATSBY_DRUPAL_USERNAME,
        password: config.GATSBY_DRUPAL_PASSWORD
    }
})

const api = {
    paymentChecksum(payload) {
        return new Promise((resolve, reject) => {
            myAxios.post(config.PAYMENT.CHECKSUM_API, payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    abcd(payload) {
        return new Promise((resolve, reject) => {
            // myAxios.post('https://uat.billdesk.com/pgidsk/PGIMerchantPayment', payload)
            //     .then((response) => {
            //         resolve(response.data)
            //     })
            //     .catch(e => {
            //         reject(e)
                //})
                
                // axios({
                //     method: 'post',
                //     url: 'https://uat.billdesk.com/pgidsk/PGIMerchantPayment',
                //     data: payload
                //   });

                // axios({
                //     method: "post",
                //     url: "https://uat.billdesk.com/pgidsk/PGIMerchantPayment",
                //     data: payload,
                //     headers: { "Content-Type": "multipart/form-data,application/x-www-form-urlencoded" },
                //   })
        })
    },

    convertJsonDataToId(payload) {
        return new Promise((resolve, reject) => {
            AuthAxios.post(config.PAYMENT.CONVERT_JSON_DATA, payload)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    getPaymentDetails(id) {
        return new Promise((resolve, reject) => {
            myAxios.post(config.PAYMENT.GET_PAYMENT_DETAILS+'/'+id)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },

    getRegCharges() {
        return new Promise((resolve, reject) => {
            myAxios.get(config.PAYMENT.GET_REGISTRATION_CHARGES)
                .then((response) => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    },
}

export default api